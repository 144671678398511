body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --accent-1: #859900;
  --accent-2: #2aa198;
  --accent-3: #268bd2;
  --accent-4: #6c71c4;
  --accent-5: #d33682;
  --accent-6: #dc322f;
  --accent-7: #cb4b16;
  --accent-8: #b58900;
  --highlight: #fffbce;
  --link-color: var(--accent-3);
  --link-color-rgb: var(--accent-3-rgb);
  --link-color-hsl: 205, 69%, 49%;
  --background-light-1: #ffffff;
  --background-light-2: #eeeeee;
  --background-dark-1: #1a1a1a;
  --background-dark-2: #000000;
  --content-1: #aaaaaa;
  --content-2: #5f6368;
  --content-3: #3e4247;
  --content-4: #202124;
  --highlight-rgb: 255,251,206;
  --background-light-1-rgb: 255,255,255;
  --background-light-2-rgb: 238,238,238;
  --background-dark-1-rgb: 26,26,26;
  --background-dark-2-rgb: 0,0,0;
  --content-1-rgb: 185,185,185;
  --content-2-rgb: 158,158,158;
  --content-3-rgb: 111,111,111;
  --content-4-rgb: 82,82,82;
  --accent-1-rgb: 133,153,0;
  --accent-2-rgb: 42,161,152;
  --accent-3-rgb: 38,139,210;
  --accent-4-rgb: 108,113,196;
  --accent-5-rgb: 211,54,130;
  --accent-6-rgb: 220,50,47;
  --accent-7-rgb: 203,75,22;
  --accent-8-rgb: 181,137,0;
  --fonts-default-size-very-small: 10px;
  --fonts-default-size-small: 12px;
  --fonts-default-size-medium: 14px;
  --fonts-default-size-big: 16px;
  --fonts-default-size-very-big: 18px;
  --fonts-default-size-extra-large: 20px;
  --fonts-default-size-texas-large: 40px;
  --fonts-default-weight-normal: normal;
  --fonts-default-weight-bold: bold;
  --fonts-default-weight-super-bold: 900;
  --fonts-default-decoration-overline: overline;
  --fonts-default-decoration-line-through: line-through;
  --fonts-default-decoration-underline: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
               monospace;
}

@media screen and (max-width: 600px) {
  table {
    width: 100%
  }

  thead {
    display: none
  }

  tbody td {
    padding-left: inherit;
    display: block;
    text-align: center
  }
}
th {
	background: #3498db;
	color: white;
	font-weight: bold;
	}

td, th {
	padding: 10px;
	border: 1px solid #ccc;
	text-align: left;
	font-size: 18px;
	}

.BaseTable__row--hovered {
  background-color: #fff !important;
}

fieldset {
  padding: 1em !important;
  background-color: #eee;
  border-radius: 0.5em;
  border: 1px solid #CCC !important;
}
fieldset legend {
  width: auto;
  margin: auto;
  background-color: #eee;
  border-radius: 0.5em;
  padding: 0 0.5em;
  border: 1px solid #CCC;
}
