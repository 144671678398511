.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 59px;
  height: 33px;
}

/* Switch 1 Specific Styles Start */

.box_1{
  background: #eee;
}

input[type="checkbox"].slider-round{
  font-size: 22px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 3.5em;
  height: 1.5em;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

input[type="checkbox"].slider-round:checked{
  background: #042833;
}

input[type="checkbox"].slider-round:after{
  position: absolute;
  content: "";
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 .25em rgba(0,0,0,.3);
  box-shadow: 0 0 .25em rgba(0,0,0,.3);
  -webkit-transform: scale(.7);
  transform: scale(.7);
  left: 0;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

input[type="checkbox"].slider-round:checked:after{
  left: calc(100% - 1.5em);
}
/* Switch 1 Specific Style End */

.penguin-button {
  color: white;
  background: #042834;
  border-radius: 1em;
  font-weight: bold;
}

.divider {
  width: 0.5em;
  height: 1em;
  display:inline-block;
}

.icon-button {
  border: transparent;
  font-size: larger;
  margin-left: 1.5em;
  background-color: transparent;
}
